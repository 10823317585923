import React, {useState} from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {useMediaQuery} from "react-responsive";
import {PhotoAlbum} from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Seo from "../components/seo";

const GallerijPage = ({data: { photos } }) => {
    const isMD = useMediaQuery({
        query: '(min-width: 768px)'
    });

    const [index, setIndex] = useState(-1);

    const formattedPhotos = photos.albumOtherPhotos.map(({external_link, width, height}) => {
        return {
            src: external_link,
            width,
            height
        }
    });

    return (
        <Layout>
            <Seo title={"Foto's & Video's"} description={""} />
            <section className={"w-full bg-white pt-10 flex flex-col items-center pb-10 font-primary text-black"}>
                <div className={"jdb--menu-spacer"} />
                <div className={"pb-2"}><h1 className={"h1"}>Foto's</h1></div>
                <div className={"flex flex-col w-full mt-10"}>
                    <PhotoAlbum layout="columns"
                                columns={isMD ? 3 : 2}
                                padding={0}
                                spacing={0}
                                photos={formattedPhotos}
                                onClick={(event, photo, index) => setIndex(index)}/>
                    <Lightbox
                        slides={formattedPhotos}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        // enable optional lightbox plugins
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                    />
                </div>
            </section>
        </Layout>
    )
};

export default GallerijPage;


export const pageQuery = graphql`
query GallerijQuery {
    photos: jdb {
        albumOtherPhotos {
            external_link
            image_url
            width
            height
        }
      }
}
`
